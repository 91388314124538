export default {
  beforeCreate() {
    this.httpResources = {
      report: this.$resource('/api/v1/user/report{/id}'),
      whoami: this.$resource('/api/v1/whoami'),
      login: this.$resource('/login?login={login}', {}, {}, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      }),
      image: this.$resource('/api/v1/user/image{/id}'),
      features: this.$resource('/api/v1/features'),
      search: this.$resource('/fuhrpark/actions/query'),

      imageURL: '/api/v1/user/image',
    };
  },
};
