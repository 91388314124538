import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/styles/main.sass';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify, {
  iconfont: 'md',
  lang: {
    locales: { de },
    current: 'de',
  },
});
