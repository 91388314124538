<template>
  <div class="header">
    <v-container>
      <v-layout class="text-xs-center">
        <v-flex xs-12>
          <img src="../assets/wisag_logo.png" alt="Wisag"><br>
          <slot></slot>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'WisagHeader',
};
</script>

<style scoped lang="scss">
img {
  margin-bottom: 10px;
}
</style>
