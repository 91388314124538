<template>
  <div id="app" :class="$route.name">
    <router-view />
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss">
@import './scss/app.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#app {
  max-width: $content-max-width;
  min-height: 100vh;
  margin: 0 auto;

  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $clr-black-base;
}

#app.login {
  background-image: url('assets/login/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: none;
}

#nav {
  padding: 30px;

  text-align: center;

  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983
    }
  }
}
</style>
