const state = {
  featureFlags: {},
};

const mutations = {
  setFeatureFlags(state, value) {
    state.featureFlags = value;
  },
};

export const getters = {
  getFahrzeugSuche: state => state.featureFlags.fahrzeug_suche,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
