const state = {
  currentJWT: '',
};

export const getters = {
  jwt: state => state.currentJWT,
  jwtData: (state, getters) => {
    try {
      if (!state.currentJWT) {
        return null;
      }

      const rawData = getters.jwt.split('.')[1];
      const callback = function cb(c) {
        const char = c.charCodeAt(0).toString(16);
        const char1 = `00${char}`.slice(-2);
        return `%${char1}`;
      };

      const decodedData = decodeURIComponent(Array.prototype.map.call(atob(rawData), callback)
        .join(''));
      return state.currentJWT ? JSON.parse(decodedData) : null;
    } catch {
      return null;
    }
  },
  name: (state, getters) => {
    const name = getters.jwtData ? getters.jwtData.name : '';
    return name;
  },
  exp: (state, getters) => {
    const exp = getters.jwtData ? getters.jwtData.exp : 0;
    return exp;
  },
};

const actions = {

};

/* eslint-disable no-param-reassign */
const mutations = {
  setJWT(state, jwt) {
    state.currentJWT = jwt;
  },
};
/* eslint-enable no-param-reassign */

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
