<template>
  <div />
</template>

<script>
export default {
  name: 'LogOut',

  beforeCreate() {
    document.cookie = 'kfzschaden=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    this.$store.commit('user/setJWT', null);
    this.$router.push({ name: 'login' });
  },
};
</script>
