import Vue from 'vue';
import VueResource from 'vue-resource';
import * as moment from 'moment';
import store from '@/store/index';

Vue.use(VueResource);

function reportsRequestInterceptor(response) {
  if (response.status >= 200 && response.status < 300 && Array.isArray(response.body)) {
    response.body.forEach((item) => {
      /* eslint-disable no-param-reassign */
      item.create_date = moment(item.create_date).format('DD.MM.YYYY');
      /* eslint-enable */
    });
  }
}

function reportRequestInterceptor(response) {
  if (response.status === 404) document.location = '/home';
}

Vue.http.interceptors.push((request) => {
  if (request.url && request.url.includes('/user/report') && request.method === 'GET') {
    if (request.params.id) return reportRequestInterceptor;
    return reportsRequestInterceptor;
  }
  return null;
});
Vue.http.interceptors.push((request) => {
  if (request.url.includes('/api/')) {
    const token = store.getters['user/jwt'];
    if (token.length) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }

    return (response) => {
      if (response.status === 401 || response.status === 403) {
        document.location = '/logout';
      }
    };
  }
  return null;
});
Vue.http.interceptors.push((request) => {
  if (request.url && request.url.includes('/fuhrpark/actions/query') && request.method === 'POST') {
    const token = store.getters['user/jwt'];
    if (token.length) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }

    return (response) => {
      if (response.status === 401 || response.status === 403) {
        document.location = '/logout';
      }
    };
  }
  return null;
});
