import Vue from 'vue';
import Router from 'vue-router';
import LogIn from '@/views/Login.vue';
import LogOut from '@/views/Logout.vue';
import HomeView from '@/views/Home.vue';
import isTokenValid from '@/guards/isTokenValid';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: LogIn,
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogOut,
    },
    {
      path: '/home',
      name: 'home',
      component: HomeView,
      beforeEnter: isTokenValid,
    },
    {
      path: '/damagereport',
      name: 'damagereport',
      // route level code-splitting
      // this generates a separate chunk (damagereport.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "damagereport" */ './views/Damagereport.vue'),
      beforeEnter: isTokenValid,
      children: [
        {
          path: ':id',
          name: 'damagereportForm',
          component: () => import(/* webpackChunkName "damagereportForm" */ './views/DamagereportForm.vue'),
          props: true,
        },
      ],
    },
  ],
});
