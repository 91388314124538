<template>
  <router-link :to="{path: `damagereport/${report.id}`}">
    <span class="report-button">
      <span :class="statusClasses"></span>
      <img src="../assets/report/report.jpg" alt=""/>
      <span class="vertical-rule"></span>
      <span class="data">{{ report.create_date }}<br>
        {{ report.fw_kennzeichen1 }}
        <template v-if="report.fw_kennzeichen2">-</template>
        {{ report.fw_kennzeichen2 }}
        <template v-if="report.fw_kennzeichen3">-</template>
        {{ report.fw_kennzeichen3 }}
      </span>
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'ReportButton',

  props: {
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    completed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      statusClasses: {
        status: true,
        isCompleted: this.completed,
      },
    };
  },

  computed: {
    kennzeichenComplete() {
      return this.report.fw_kennzeichen1.length
        && this.report.fw_kennzeichen2.length
        && this.report.fw_kennzeichen3.length;
    },
  },

  mounted() {
  },
};
</script>

<style lang="scss" scoped>
@import './../scss/settings.scss';

a {
  width: calc(100% - 20px);
  padding-top: 1px;
  padding-right: 1px;
  padding-bottom: 1px;
  margin-bottom: 10px;

  background: linear-gradient(315deg, transparent 20px, $clr-black-xlight 0) bottom right;

  display: block;
}

.report-button {
  width: 100%;
  height: 60px;
  background: linear-gradient(315deg, transparent 20px, #fff 0) bottom right;
  border-left: none;

  display: block;
}

.status {
  width: 8px;
  height: 100%;
  margin-right: 20px;

  background-color: $clr-orange-base;

  display: inline-block;
  vertical-align: middle;
}
.status.isCompleted {
  background-color: $clr-green-base;
}

.vertical-rule {
  width: 1px;
  height: 65%;
  margin-right: 20px;

  background-color: $clr-black-xlight;

  display: inline-block;
  vertical-align: middle;
}

.data {
  display: inline-block;
  vertical-align: middle;
}

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;

    display: inline-block;
    vertical-align: middle;
  }
</style>
