<template>
  <div class="home">
    <wisag-header>{{ userName }}</wisag-header>

    <section>
      <span class="cta-button">
        <img
          class="cta-button__icon"
          src="../assets/icons/unfall.png"
          alt="Zwei Auto verursachen einen Unfall"
        />
        <router-link :to="{name: 'damagereport'}">
          Schaden melden
        </router-link>
      </span>
    </section>
    <section v-if="reportsNotCompleted && reportsNotCompleted.length">
      <h3>Offene Schadensmeldungen</h3>
      <ul>
        <li v-for="report in reportsNotCompleted" :key="report.id">
          <report-button :report="report"></report-button>
        </li>
      </ul>
    </section>
    <section v-if="reportsCompleted && reportsCompleted.length">
      <h3>Abgeschlossene Schadensmeldungen</h3>
      <ul>
        <li v-for="report in reportsCompleted" :key="report.id">
          <report-button :report="report" :completed="true"></report-button>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WisagHeader from '@/components/Header.vue';
import ReportButton from '@/components/ReportButton.vue';

import httpResourcesMixin from '@/mixins/httpResources';

export default {
  name: 'homeView',

  components: {
    WisagHeader,
    ReportButton,
  },

  data() {
    return {
      reportsCompleted: [],
      reportsNotCompleted: [],
    };
  },

  created() {
    this.getReports();
  },

  computed: {
    ...mapGetters({
      userName: 'user/name',
    }),
  },

  methods: {
    getReports() {
      this.httpResources.report.get()
        .then((response) => {
          this.reportsNotCompleted = Object.values(response.body).filter((item) => {
            if (typeof item === 'object' && item.id && !item.schadensmeldung_abgeschlossen) return true;
            return false;
          });
          this.reportsCompleted = Object.values(response.body).filter((item) => {
            if (typeof item === 'object' && item.id && item.schadensmeldung_abgeschlossen) return true;
            return false;
          });
        });
    },
  },

  mixins: [
    httpResourcesMixin,
  ],
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 80px;
}

h3 {
  padding-left: 15px;
  margin-bottom: 10px;
}

section {
  margin-bottom: 40px;
}

ul {
  padding: 0;
  list-style: none;
}
</style>
