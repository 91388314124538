<template>
  <div class="login">
    <v-form
      v-if="loginURL"
      :action="loginURL"
      method="POST"
    >
      <input type="hidden" name="SAMLRequest" :value="samlRequest" />
      <v-layout wrap class="gap-row">
        <v-flex xs12>
          <button
            class="cta-button"
            :disabled="!formValid"
            color="success"
            type="submit"
          >
            Login
          </button>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import getCookie from '../sharedFunctions/getCookie';

export default {
  name: 'LogIn',
  data() {
    return {
      formValid: true,
      loginURL: null,
      samlRequest: null,
      // coo: 'no cookies found',
    };
  },

  mounted() {
    this.checkUserToken();
    this.getLoginURL();
    // this.checkCookieAccess();
  },

  methods: {
    // checkCookieAccess() {
    //   this.coo = document.cookie;
    // },

    checkUserToken() {
      // const coo = getCookie('kfzschaden');
      // this.$http.post('/frontend-logger', {
      //   method: 'checkUserToken',
      //   token: this.jwt,
      //   cookie: coo,
      // });

      if (
        this.jwt
        && this.jwtExp
        && this.jwtExp - 120 > Math.ceil(Date.now() / 1000)
      ) {
        // this.$http.post('/frontend-logger', { method: 'checkUserToken: valid' });
        this.$router.push('home');
      } else {
        // this.$http.post('/frontend-logger', { method: 'checkUserToken: invalid' });
      }
    },

    getLoginURL() {
      this.$http.get('/auth/saml/login-idp-url')
        .then((response) => {
          if (response.body.idp_uri && response.body.SAMLRequest) {
            this.loginURL = response.body.idp_uri;
            this.samlRequest = response.body.SAMLRequest;
          }
        });
    },
  },

  computed: {
    ...mapGetters({
      jwt: 'user/jwt',
      jwtExp: 'user/exp',
    }),
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding-top: 80px;
}
</style>
